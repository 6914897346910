.TermsAndConditionsForm {
    &__container {
        display: grid;
        grid-template-columns: max-content 1fr;
        row-gap: 1.5em;
        column-gap: 1em;
    }

    &__preamble {
        grid-column: 1 / -1;
    }

    &__item {
        display: contents;
    }

    &__checkbox{
        align-self: start;
        justify-self: center;

        width: 8.5rem;
    }

    &__textBlock {
    }

    &__text {
        text-align: justify;
    }

    &__button {
        display: flex;

        width: 100%;

        justify-content: flex-end;

        & > button {
            width: 8em;
        }
    }
}