@import "../styles/theme.scss";

$border-color: $primary-color;
$icon-width: 1.5em;

.PaymentForm {
    &__logos {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        width: 100%;
        height: 5rem;
    }

    &__paymentTypes {
        margin-top: 2rem;
    }
    &__failText{
        padding-bottom:2.125rem;
        border-bottom: 0.01px solid #E9A837;
    }
    &__depositOnlyWrapper{
        display: flex;
        margin: 1.25rem 0 2rem;

    }
    &__depositOnlyText{
        width: 50%;
        text-align: center;
        font-weight: 400;
        font-size: medium;
    }
    &__depositAmount{
        margin-left: 19px;
        font-weight: 400;
        color: #E9A837;
        font-size: large;
    }
    &__navWrapper{
        margin-top:2rem;
    }
    &__separator{
      height: 0.01rem;
      background: #E9A837;
      margin-top: 2.25rem;

    }
    &__logo {
        margin-top: 1rem;

        &_paypal {
            height: 3rem;

            text-align: left;
        }

        &_paypal img {
            height: 80%;
        }

        &_google { 
            height: 3rem;
            margin: -0.5rem;

            text-align: center;
        }

        &_google img {
            height: 200%;
        }

        &_apple {
            height: 3rem;
            width: 100%;

            text-align: right;
        }

        &_apple svg {
            height: 3rem;
            width: auto;
        }
    }
}