.Address {
    &__container {
        display: grid;
        row-gap: 0.2rem;
    }

    &__regionGroup {
        display: grid;
        grid-template-columns: 3fr 2fr 3fr;
        column-gap: 0.2rem;
    }
}