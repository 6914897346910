@import "../styles/theme.scss";

.Panel {
    display: inline-block;
    position: relative;

    width: 22cm;

    margin-top: 2rem;

    &:last-of-type {
        margin-bottom: 2rem;
    }

    &__noPadding {
        margin-top: 0;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &__container {
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__box {
        position: relative;
        padding: 1.5cm;
        width: 80em;
        max-width: 80%;

        background: $secondary-background-color;

        border-radius: 1cm;
    }

    &__boxNoPadding {
        padding: 0;
    }

    &__logo {
        margin-bottom: 1em;
    }

    &__title {
        font-size:28px;

        color: $text-color;

        margin-bottom: 1.688rem;
    }

    &__content {
        width: 100%;
    }
}