@import "../styles/theme.scss";

.Select {
    width: 100%;

    border: none;
    border-radius: $input-radius;
    padding: $input-padding;

    margin-bottom: 0;

    color: $input-color;
    background-color: $input-background;
    
    outline-style: solid;
    outline-color: transparent;
    outline-width: 1px;

    &:focus {
        outline-color: $secondary-color-active;
    }

    &:disabled {
        color: black;
    }
}
