@import "../styles/theme.scss";

.Message {
    &__container {
        display: grid;
        grid-template-columns: 2rem auto 1rem;
        column-gap: 1rem;
        align-items: center;

        margin-top: 1rem;
        padding: 1rem;
        border-radius: $secondary-background-radius;

        &_informational {
            background-color: #70E9D4;
            color: #000000;
        }
    
        &_warning {
            background-color: #E9A837;
            color: #000000;
        }
    
        &_error {
            background-color: #EB5757;
            color: #000000;
        }
    
        &_critical {
            background-color: darkred;
            color: white;
        }
    }

    &__icon {
        width: 2rem;
    }

    &__closeIcon {
        width: 1rem;
        border: none;
        outline: none;

        background-color: transparent;
    }

    &__body {

    }

    &__header {

    }

    &__content {

    }

    &__close {
        align-self: flex-start;
    }

    &__icon {
    }
}
