@import "../styles/theme.scss";

.Dashboard {
    display: grid;
    grid-template-columns: max-content auto;
    
    width: 100vw;
    height: 100vh;

    overflow: hidden;

    // background-image: url("../res/images/solarpanels.jpeg");
    background-repeat: no-repeat;
    background-size: cover;

    &__content {
        display: flex;
        flex-direction: column;
        // justify-content: center;
        align-items: center;

        overflow-y: auto;
        overflow-x: hidden;
    }

    &__name {
        color: $sidebar-text-color;
    
        font-size: 0.8rem;

        position: absolute;
        top: 0em;
        right: 0em;
    }
}