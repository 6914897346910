@import "../styles/theme.scss";

.Modal {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    z-index: 1;
    margin-top: 2rem;

    font-family: $font-family;
    font-size: $font-size;
    
    &:last-of-type {
        margin-bottom: 2rem;
    }

    &__shade {
        display: flex;
        align-items: center;
       justify-content: center;
        
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        z-index: -1;

        backdrop-filter: blur(4px);
        background-color: rgba(#000000, 0.75);
    }
}
