@import "../styles/theme.scss";

.MySavingsPanel {
    &__header {
        display: grid;
        grid-template-columns: 1fr max-content;

        margin-bottom: 1.5em;
    }

    &__title {
        align-self: center;

        font-size: 2em;
    }

    &__data {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 1em;

        justify-items: center;

        margin-top: 2em;
    }

    &__joinedContainer {
    }

    &__joinedLabel {
        padding-right: 0.5em;
    }

    &__joinedValue {
        color: $primary-color;
    }

    &__calculator {
        display: grid;

        grid-template-columns: max-content 1fr max-content;
    }

    &__billContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__billGroup {
        display: flex;
        flex-direction: column;

        margin-bottom: 1.5rem;
    }

    &__billLabel {
        white-space: nowrap;
        margin-bottom: 1em;
    }

    &__billInput {
        display: flex;
        align-items: center;
    }

    &__dollarSign {
        margin: 0 0.5em;
    }

    &__graphContainer {
        height: 10rem;
        width: 10rem;

        position: relative;

        align-self: center;
        justify-self: center;
    }

    &__savingsGraph {
    }

    &__savingsGraphOverlay {
        text-align: center;

        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        position: absolute;
    }

    &__informationContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__informationGroup {
        display: flex;
        flex-direction: column;

        margin-bottom: 1.5rem;
    }

    &__infoBillLabel {
        white-space: nowrap;
    }

    &__infoBill {
        font-size: 1.2rem;
        color: $primary-color;

        padding-top: 0.25em;
    }

    &__infoSavingsLabel {
        white-space: nowrap;

        padding-top: 1em;
    }

    &__infoSavings {
        font-size: 1.2rem;
        color: $primary-color;

        padding-top: 0.25em;
    }
}
