$forground-colour: rgba(255,255,255,1);
$logo-colour: rgba(254,171,67,1);

.ToggleSwitch {
    display: block;
    text-align: center;

    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 26px;
    color: $forground-colour;
    text-decoration: none;

    margin: 16px 0px;

    &__switch {
        position: relative;
        display: inline-block;
        top: 8px;
        width: 50px;
        height: 20px;
        background-color: $logo-colour;
        border-radius: 20px;
    }

    &__label {
        &_left {
            padding-right: 0.25em;
        }

        &_right {
            padding-left: 0.25em;
        }
    }

    &__input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    input:checked + &__visual:before {
        transform: translateX(28px);
    }

    &__visual {
        background-color: $logo-colour;
        border-radius: 20px;
    }

    &__visual:before {
        position: absolute;
        content: "";
        height: 16px;
        width: 16px;
        border-radius: 50%;
        left: 3px;
        bottom: 2px;
        background-color: $forground-colour;
        -webkit-transition: .4s;
        transition: .4s;
    }
}