@import "../styles/theme.scss";

.DatasetChart {
    &__background {
        fill: black;
        stroke: none;
        opacity: 0;
    }

    &__xAxisLabel {
        fill: $text-color;

        font-size: 6pt;
        font-weight: bolder;

        text-anchor: end;
    }

    &__yAxisLabel {
        fill: $text-color;

        font-size: 6pt;
        font-weight: bolder;

        text-anchor: end;
    }

    &__bar {
        fill: $secondary-color;

        stroke: none;

        &:hover {
            fill: $primary-color;
        }
    }

    &__day {
        fill: $text-color;

        font-size: 5pt;

        text-anchor: middle;
    }

    &__value {
        fill: $text-color;

        font-size: 5pt;

        text-anchor: end;
    }

    &__popup {
        &_visible {
            visibility: inherit;
        }

        &_hidden {
            visibility: hidden;
        }
    }

    &__popupBackground {
        fill: rgba($primary-color, 0.8);

        stroke: none;
    }

    &__popupText {
        fill: $popup-text-color;

        font-size: 6pt;

        text-anchor: middle;
    }
}