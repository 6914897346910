.LocationsPanel {
    display: block;
    position: relative;

    width: 14.5cm;
    height: 14.5cm;

    &__marker {
        width: 25px;
        height: 25px;

        padding: 6px;

        transform: translate(-12.5px, -12.5px);

        border-style: solid;
        border-color: #A1A1A6;
        border-width: 1px;
        background-color: #A1A1A6;
        border-radius: 50px;
    }
}