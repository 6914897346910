.EnergyPanel {

    &__header {
        display: grid;
        grid-template-columns: 1fr max-content;

        margin-bottom: 1.5em;
    }

    &__title {
        align-self: center;

        font-size: 2em;
    }

    &__spinner {
        justify-self: right;
    }

    &__data {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        column-gap: 1em;
        
        justify-items: center;

        margin-bottom: 2em;
    }

    &__chart {
    }
}