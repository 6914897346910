@import "../styles/theme.scss";

.LoadingPage {
    &__spinnerContainer {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__spinner {
        display: inline;

        height: 2em;
        width: 2em;

        vertical-align: middle;
    }

    &__spinner > svg {
        stroke: $sidebar-text-color;
        fill: $sidebar-text-color;

        height: 2em;
        width: 2em;

        animation-name: spinner;
        animation-duration: 1s;
        animation-timing-function: steps(8);
        animation-delay: 0s;
        animation-iteration-count: infinite;
        animation-direction: normal;
        animation-fill-mode: none;
        animation-play-state: running;
    }
}

@keyframes spinner {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}