@use "sass:color";

$primary-color: #E9A837;
$primary-color-hover: color.scale($primary-color, $lightness: 10%);
$primary-color-focus: color.scale($primary-color, $lightness: 15%);
$primary-color-active: color.scale($primary-color, $lightness: 25%);

$secondary-color: #70E9D4;
$secondary-color-hover: color.scale($secondary-color, $lightness: 10%);
$secondary-color-focus: color.scale($secondary-color, $lightness: 15%);
$secondary-color-active: color.scale($secondary-color, $lightness: 25%);

$tertiary-color: #336cdf;
$tertiary-color-hover: color.scale($tertiary-color, $lightness: 10%);
$tertiary-color-focus: color.scale($tertiary-color, $lightness: 15%);
$tertiary-color-active: color.scale($tertiary-color, $lightness: 25%);

$background-color: #23262e;
$background-color-hover: color.scale($background-color, $lightness: 10%);
$background-color-focus: color.scale($background-color, $lightness: 15%);
$background-color-active: color.scale($background-color, $lightness: 25%);

$background-radius: 10mm;

$secondary-background-color: #141416;
$secondary-background-color-hover: color.scale($secondary-background-color, $lightness: 10%);
$secondary-background-color-focus: color.scale($secondary-background-color, $lightness: 15%);
$secondary-background-color-active: color.scale($secondary-background-color, $lightness: 25%);

$secondary-background-radius: 5mm;

$text-color: #e0e0e0;
$popup-text-color: #23262e;

$sidebar-text-color: #808191;
$sidebar-text-color-selected: $secondary-color;
$sidebar-text-color-hover: color.scale($sidebar-text-color-selected, $lightness: 10%);
$sidebar-text-color-focus: color.scale($sidebar-text-color-selected, $lightness: 15%);
$sidebar-text-color-active: color.scale($sidebar-text-color-selected, $lightness: 25%);

$notification-text-color: #23262e;

$input-radius: 0.5em;
$input-color: $text-color;
$input-background: $background-color;
$input-padding: 0.6em 1em;
$input-width: calc(100% - 2em);
$input-outline-style: solid;
$input-outline-color: transparent;
$input-outline-color-focus: $secondary-color-active;
$input-outline-color-error: darkred;
$input-outline-width: 1px;

$button-font-size: 12pt;
$button-padding-top: 0.75em;
$button-border: 2px;
$button-border-width: 2px;
$button-disabled-color: #141416;
$button-disabled-background-color: #A1A1A6;
$button-radius:1.25rem;

$delete-color: darkred;
$error-color: darkred;
$warning-color: #6dc3c9;

$font-family: 'Roboto', sans-serif;
$font-family-poppins: "Poppins", sans-serif;
$font-size: 12pt;
