@import "../styles/theme.scss";

.OrderSummaryForm {
  &__depositAmount {
    white-space: nowrap;
    text-align: right;
    font-size: 1rem;
    margin-left: 2rem;
  }

  &__payOnlyText {
    font-size: 1rem;
    color: $primary-color;
    text-align: center;
    margin-top: 1rem;
  }

  &__payOnlyTextLeft {
    font-size: 1rem;
    text-align: left;
    font-weight: 400;
  }

  &__totalsContainer {
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 1rem;
  }

  &__totalContainer {
    display: contents;
  }

  &__totalContainerWrapper {
    display: flex;
    color: #e9a837;
    justify-content: flex-end;
    width: 86%;
    margin: 1.5rem;
  }

  &__totalBalanceWrapper {
    display: flex;
    width: 83%;
    margin: 1.5rem;
    justify-content: flex-end;
  }

  &__totalContainerPanelsPage {
    display: flex;
    justify-content: space-around;
    margin-top: 1.313rem;
    border-bottom: 0.01rem solid #e9a837;
    padding-bottom: 1.313rem;
  }
  &__container {
    border-bottom: 0.01rem solid #e9a837;
  }

  &__totalPaymentGstWrapper {
    display: flex;
    flex-direction: column;
    margin-right: 37px;
  }
  &__totalPaymentdeposit {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &__totalText {
    font-size: small;
    font-weight: 600;
  }
  &__labelText {
    font-size: x-small;
  }
  &__labelText_depositText {
    margin-right: 3.2rem;
  }
  &__labelText_balanceText {
    margin-right: 2.9rem;
  }

  &__valueText {
    font-size: small;
    margin-right: 0.4rem;
    &_depositValue {
      margin-right: 0.688rem;
    }
  }

  &__gstText {
    font-size: smaller;
  }

  &__total {
    margin-bottom: 0.938rem;

    &_label {
      white-space: nowrap;

      text-align: right;

      font-size: 1rem;
    }

    &_value {
      white-space: nowrap;

      text-align: right;

      font-size: 1rem;
    }

    &_payment {
      white-space: nowrap;

      font-size: 1.2rem;
      color: $primary-color;
      margin-right: 11px;
    }
  }

  &__title {
    width: 100%;

    text-align: center;
    color: $primary-color;
    font-size: 1.5rem;

    padding-top: 1rem;
  }

  &__depositInfo {
    font-size: 1rem;

    padding-top: 0.5rem;
  }

  &__holdInfo {
    font-size: 0.65rem;

    padding-top: 0.5rem;
  }
}
