@import "../styles/theme.scss";

.SavingsGraph {
    &__container > svg {
        width: 100%;
        height: 100%;
    }

    &__background {
        stroke: none;
        fill: #242731;
        ;
    }

    &__arc {
        stroke: $secondary-color;
        stroke-width: 10;
        stroke-linecap: round;
        fill: none;
    }

    &__arcSecondary {
        stroke: $tertiary-color;
        stroke-width: 10;
        stroke-linecap: round;
        fill: none;
    }

    &__arcExtra {
        stroke: $primary-color;
        stroke-width: 10;
        stroke-linecap: round;
        fill: none;
    }
}