@import "../styles/theme.scss";

.DataBox {
    display: grid;
    grid-template-rows: repeat(3, auto);
    justify-items: center;

    width: 8em;
    height: 6em;

    background-color: $background-color;
    border-radius: $secondary-background-radius;

    &__title {
        color: $text-color;

        font-size: small;
        font-weight: bolder;

        margin-top: 0.5em;
    }

    &__value {
        color: $primary-color;
    }

    &__icon {
        color: $secondary-color;
        stroke: $secondary-color;
    }
}