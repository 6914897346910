@import "../styles/theme.scss";

.PaymentAcknowledgeForm {
    &__textSection {
        font-size: 11pt;
    }

    &__textSection > p {
        margin-top: 2rem;
        margin-bottom: 2rem;
        width: 54%;
    }
}