.LoginPage {
    &__socialContainer {
        width: 100%;
    }

    &__formContainer {
        width: 100%;
    }

    &__spacer {
        height: 1em;

        &_large {
            height: 2em;
        }
    }

    &__emailHeader {
        text-align: center;
    }

    &__container {
        width: 100%;
        height: 100%;

        display: flex;
        align-items: center;
        justify-content: center;

        background: rgba(#000000, 0.8);
        backdrop-filter: blur(2px);
    }

    &__submitLine {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 1em;
    }

    &__links {
        display: grid;
        grid-template-rows: 1fr 1fr;
        row-gap: 0.5em;
    }

    &__link {
        justify-self: right;
        align-self: center;
    }
}