@import "../styles/theme.scss";

$border-color: $primary-color;
$icon-width: 1.5em;

.BuyPanelsForm {
    &__coverageContainer {
        width: 100%;
        margin-bottom: 1rem;
    }

    &__coverage {
        display: grid;
        grid-template-columns: auto 1fr;
        column-gap: 0.5rem;

        margin-bottom: 0.5rem;
    }

    &__coverageLabel {}

    &__coverageValue {
        text-align: right;
    }

    &__preselectionsContainer {
        margin-top: 1rem;
       
    }

    &__preselections {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 0.5rem;

        margin-bottom: 1rem;
      

        .Button {
            height: 7rem !important;
            border-radius: 0.5rem;
          
            .Button__text {
                display: flex;
                font-size: 12pt;
                flex-direction: column;
                z-index: 1;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                pointer-events: none;
                white-space: nowrap;
                
            }
        }
    }

    &__preselection {
        text-wrap: wrap;

        &_title {
            font-weight: bold;
        }


        &_panels {
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;

            font-size: small;
        }

        &_price {
            font-size: small;
        }
    }

    &__averagePanels {
        font-size: small;
    }

    // &__iconContainer {
    //     width: $icon-width;

    //     text-align: center;
    // }

    // &__iconButton {
    //     // width: $icon-width;
    //     padding: 0;

    //     border: none;
    //     background-color: transparent;
    // }

    // &__icon {
    //     width: $icon-width;
    //     height: $icon-width;

    //     fill: $secondary-color;

    //     cursor: pointer;

    //     &_delete {
    //         fill: $delete-color;
    //     }
    // }

    // &__joinedContainer {}

    // &__joinedLabel {
    //     padding-right: 0.5em;
    // }

    // &__joinedValue {
    //     color: $primary-color;
    // }

    &__order {
        display: flex;
        flex-direction: column;
    }

    &__orderSeparator {
        grid-column: 1 / 4;

        margin-top: 1.3rem;
        margin-bottom: 1.3rem;

    }

    &__orderItemContainer {
        display: contents;
    }

    &__orderItemHeaders {
        display: flex;
        justify-content: space-around;
        font-weight: 400;
        padding-bottom:1.375rem;

    }

    &__orderItemHeader {
        &_quantity {
            margin-right: -0.43rem;
        }

        &_capacity {
            margin-left: -0.3rem;

        }

        &_units {
            font-size: x-small;
        }

        &_price {
            text-align: right;
        }
    }

    &__orderItems {
        display: flex;
        justify-content: space-around;
        padding: 1.25rem 0;
        border-bottom: 0.01rem solid #E9A837;
        border-top: 0.01rem solid #E9A837;

    }

    &__orderItem {
        &_quantity {
            width: 20%;
        }

        &_capacity {
            width: 25%;
            text-align: center;
        }

        &_price {
            width: 25%;
            text-align: center;
        } 
        &_inputQuantity{
            text-align: center;
        }
    }

    // &__billContainer {
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: center;
    //     align-items: center;
    // }

    // &__billGroup {
    //     display: flex;
    //     flex-direction: column;

    //     margin-bottom: 1.5rem;
    // }

    // &__billLabel {
    //     white-space: nowrap;
    //     margin-bottom: 1em;
    // }

    // &__billInput {
    //     display: flex;
    //     align-items: center;
    // }

    // &__dollarSign {
    //     margin: 0 0.5em;
    // }

    // &__graphContainer {
    //     height: 15rem;
    //     width: 15rem;

    //     position: relative;

    //     align-self: center;
    //     justify-self: center;
    // }

    // &__savingsGraph {}

    // &__savingsGraphOverlay {
    //     text-align: center;
    //     font-size: 1.5rem;

    //     left: 50%;
    //     top: 50%;
    //     -webkit-transform: translate(-50%, -50%);
    //     -moz-transform: translate(-50%, -50%);
    //     transform: translate(-50%, -50%);
    //     position: absolute;
    // }

    &__informationContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__informationGroup {
        display: flex;
        flex-direction: column;

        margin-bottom: 1.5rem;
    }

    &__infoBillLabel {
        white-space: nowrap;
        display: grid;
        grid-template-columns: min-content min-content min-content;
        column-gap: 0.5rem;
    }

    &__infoBill {
        display: flex;
        align-items: center;

        font-size: 1.2rem;
        color: $primary-color;

        padding-top: 0.25em;
    }

    &__infoExistingLabel {
        white-space: nowrap;

        padding-top: 1em;
    }

    &__infoExisting {
        display: flex;
        align-items: center;

        font-size: 1.2rem;
        color: $primary-color;

        padding-top: 0.25em;
    }

    &__infoExtraLabel {
        white-space: nowrap;

        padding-top: 1em;
    }

    &__infoExtra {
        display: flex;
        align-items: center;

        font-size: 1.2rem;
        color: $primary-color;

        padding-top: 0.25em;
    }

    &__infoSavingsLabel {
        white-space: nowrap;

        padding-top: 1em;
    }

    &__infoSavings {
        font-size: 1.2rem;
        color: $primary-color;

        padding-top: 0.25em;
    }

    &__totalsContainer {
        display: grid;
        grid-template-columns: 1fr auto;
        column-gap: 1rem;
    }

    &__totalContainer {
        display: contents;
    }

    &__total {
        &_label {
            white-space: nowrap;

            text-align: right;

            font-size: 1rem;

            padding-top: 0.5rem;
        }

        &_value {
            white-space: nowrap;

            text-align: right;

            font-size: 1rem;

            padding-top: 0.5rem;
        }

        &_payment {
            white-space: nowrap;

            font-size: 1.25rem;
            color: $primary-color;

            padding-top: 0.25em;
        }
    }

    &__footnotes {
        font-size: x-small;
    }

    &__navButton {
        display: flex;
        justify-content: flex-end;
        border-top:0.01rem solid #E9A837;
        border-bottom:0.01rem solid #E9A837;
        margin-top: 1.5rem;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;

        .BuyPanelsPanel__actionContainer{
            width:50%;
        }
    }
   
}