.ProfileForm {
  &__container {
    position: relative;
  }
  &__note {
    margin-top: 2rem;
    font-size: small;
    margin-bottom: 0.5rem;

    span {
      color: red;
      font-weight: 600;
    }
  }

  &__or {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;
    div{
      font-size: larger;
    }
  }
  &__textSection {
  }

  &__textSection > p {
    margin-top: 1rem;
  }

  &__text_subText {
    font-size: xx-small;
    margin-bottom: 1.5rem;
  }

  &__nameLabel {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-weight: 400;
    font-size: small;
  }
  &__numberLabel {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    font-size: small;
  }

  &__numberLabel__text {
    font-size: x-small;
  }
  &__inputGroup{
    font-size: small;
  }

  &__names {
    // display: grid;
    // grid-template-columns: 1fr 1fr;
    column-gap: 0.5rem;
    row-gap: 0.5rem;
  }

  &__givenName {
    grid-column: 1 / 2;

    width: 100%;
    font-weight: 400;
    font-size: small;
  }

  &__familyName {
    grid-column: 2 / 3;

    width: 100%;
  }

  &__phoneLabel {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-weight: 400;
    font-size: small;
  }

  &__phone {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 0.5rem;
    row-gap: 0.5rem;
  }

  &__addressLabel {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: small;

    div {
      font-size: x-small;
    }
  }

  &__address {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 0.5rem;
    row-gap: 0.5rem;
    
  }

  &__addressSearch {
  }

  &__addressManual {
  }

  &__manualAddress {
    width: fit-content;
  }
  &__navButton {
    margin-top: 30px;
  }
  &__address_box{
    opacity: 0.4;
    color: #FFD602D4;
    font-size: large;
    font-weight: 600;
    margin-top: 2rem;
  }
}
