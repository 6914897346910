.EnergyBillForm {
    &__energyBillDescription {
        text-align: center;

        & p {
            margin-bottom: 0.5rem;
        }
        
        padding-bottom: 1rem;
    }

    // &__divider {
    //     text-align: center;

    //     padding-top: 1rem;
    //     padding-bottom: 1rem;
    // }

    &__bills {
        display: grid;
        row-gap: 0.25rem;

        align-items: center;
        justify-content: center;
    }

    &__bill {
        width: 10rem;
    }
}
