@import "../styles/theme.scss";

.Notification {
    display: inline-block;
    position: relative;

    width: 20cm;
    // height: 100%;

    &__container {
        width: 100%;
        // height: 100%;

        display: flex;
        align-items: center;
        justify-content: center;

        // background-color: #141416;
        // background: rgba(#000000, 0.8);
        // backdrop-filter: blur(2px);

        margin-top: 2rem;
    }

    &__box {
        position: relative;
        padding: 1.5cm;
        max-width: 35rem;

        display: grid;
        grid-template-columns: 1fr 10rem;
        grid-template-rows: min-content 1fr;
        
        width: 100%;
        // display: flex;
        // flex-direction: column;
        // align-items: center;
        // justify-content: center;

        color: $notification-text-color;
        background: $secondary-color;
        // backdrop-filter: blur(8px);

        border-radius: 1cm;
    }

    &__logo {
        margin-bottom: 1em;
    }

    &__title {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        
        font-size: 2rem;
        // color: #E9A837;

        margin-bottom: 1em;
    }

    &__content {
        display: contents;
    }

    &__header {
        display: grid;
        grid-template-columns: 1fr max-content;

        margin-bottom: 1.5em;
    }

    &__title {
        align-self: center;

        font-size: 2em;
    }

    &__bodyContainer {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }

    &__bodyItem {
    }

    &__links {
        display: grid;

        grid-template-columns: min-content;

        justify-content: right;
        align-content: center;

        padding-top: 1rem;
    }

    &__iconContainer {
        grid-column: 2 / 3;
        grid-row: 1 / 3;

        width: 100%;
        height: 100%;
    }

    &__iconContainer > svg {
        width: 100%;
        height: 100%;
    }
}