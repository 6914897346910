.ReferAFriend {
  margin-top: 1.875rem;

  &__referWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__logo {
    display: flex;

    &_twitter,
    &_linkedin,
    &_fb,
    &_insta {
      img {
        height: 2rem;
        width: 2rem;
        margin-left: 1rem;
      }
    }

    &_human {
      margin-right: 0.313rem;

      cursor: pointer;

      img {
        height: 3.5rem;
        width: 3.5rem;
      }
    }

    &_share {
      cursor: pointer;

      img {
        height: 2.6rem;
        width: 2.6rem;
      }
    }

    &_referText {
      font-size: 22px;
      font-weight: 400;
      font-style: normal;
      margin-right: 0.313rem;

      cursor: pointer;
    }
  }

  .referWrapper {
    margin-top: 0.75rem;
  }

  &__modal {
    padding: 0.5rem;
    background-color: white;
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  &__share {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__closeBtn {
    color: black;
    font-size: 1.9rem;
    cursor: pointer;
  }

  &__sharetext {
    color: black;
    font-size: 1.1rem;
    font-weight: 400;
  }

  &__shareOptionsWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    flex-wrap: wrap;
  }

  &__shareOptions {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    width: 100%;
    span {
      color: black;
    }
  }

  &__shareOption {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
  }

  &__iconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;

    &--facebook {
      background-color: #3b5998;
      color: white;
    }
    &--messenger {
      background-color: #0084ff;
      color: white;
    }
    &--twitter {
      background-color: #1da1f2;
      color: white;
    }
    &--linkedin {
      background-color: #0077b5;
      color: white;
    }
    &--whatsapp {
      background-color: #25d366;
      color: white;
    }
    &--telegram {
      background-color: #0088cc;
      color: white;
    }
    &--email {
      background-color: #8f8c8c;
      color: white;
    }
    &--reddit {
      background-color: #ff4500;
      color: white;
    }
    &--pinterest {
      background-color: #bd081c;
      color: white;
    }
    &--tumblr {
      background-color: #36465d;
      color: white;
    }
    &--slack {
      background-color: #4a154b;
      color: white;
    }
    &--blogger {
      background-color: #f57d00;
      color: white;
    }
  }

  &__linkSection {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    padding: 0.5rem;
    border: 1px solid #e1e4e8;
    border-radius: 1rem;
  }

  &__linkInput {
    flex-grow: 1;
    padding: 0.5rem;
    border: none;
    background: none;
    outline: none;
    font-size: 1rem;
  }

  &__copyButton {
    padding: 0.5rem 1rem;
    margin-left: 0.5rem;
    border: none;
    background-color: #0366d6;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 1rem;
  }

  &__copySuccess {
    margin-left: 0.5rem;
    color: #28a745;
    font-size: 0.9rem;
  }
}
