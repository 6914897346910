@import "../styles/theme.scss";

.SignupUnavailableForm {
    &__container {
        // display: grid;
        // grid-template-columns: 1fr 1fr;
        // column-gap: 2em;
    }

    &__spacer {
        height: 1em;

        &_large {
            height: 2em;
        }
    }

    &__input {
        width: calc(100% - 24pt);
    
        margin-top: 0.5rem;
    }

    &__links {
        text-align: right;
    }
}
