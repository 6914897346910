@import "./theme.scss";

*::-webkit-scrollbar {
  width: 12px;
  background: $background-color;
}

*::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: $secondary-background-color;
}

* {
  scrollbar-color: $secondary-background-color $background-color;
}