@import "../styles/theme.scss";

.GotQuestion {

&__text2 {
    font-size: 1.375rem;
    margin-top: 1.9rem;
    text-align: center;
    color: $primary-color;
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
}
}