.ResetPasswordForm {
    &__containere {
        width: 100%;
    }
    &__requestForm {
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 1em;
    }

    &__input {
    }

    &__actions {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 1em;

        align-items: center;
        justify-items: center;
    }

    &__button {
    }

    &__links {
        justify-self: right;
    }

    &__resetForm {
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 1em;
    }

}