$separator-distance: 1em;

.Separator {
    width: 100%;
    color: #E9A837;

    margin: 1rem 0;

    display: grid;
    grid-template-columns: 1fr max-content 1fr;

    justify-content: center;
    align-items: center;

    &_zeroMargin {
        margin: 0;
    }

    &__line {
        display: inline-block;
        background: #E9A837;
        height: 1px;

        &_pre {
            margin-right: $separator-distance;
        }

        &_post {
            margin-left: $separator-distance;
        }

        &_full {
            grid-column: 1 / 4;
        }
    }

    &__text {
        display: inline-block;
    }
}