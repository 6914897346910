.CreditCardInput {
    &__container {
        display: flex;
        margin-top: 1em;
        justify-content: space-between;
    }

    &__form {
        display: grid;
        row-gap: 0.5em;
        width: 50%;
    }
    
    &__creditCard {
        &_error {
            outline-color: red !important;
        }
    }
}