@import "../styles/theme.scss";

.FederatedLogins {
    &__container {
        display: grid;

        grid-template-rows: 1fr;

        gap: 0.5em;
    }

    &__oauthButton {
        position: relative;
        
        display: flex;
        align-items: center;

        height: 2.5rem;
        width: 100%;

        border: none;
        border-radius: 5px;

        background-color: #FFFFFF; // required by Google
        box-shadow: 0px 1px 5px -3px #000000; // required by Google

        padding: 1mm;

        &:focus {
            background-color: #F3F3F3; // required by Google
            
        }

        &:active {
            background-color: #cccccc; // required by Google

        }

       
    }

    &__oauthLogo {
        &_google {
            height: 4rem;
        }

        &_apple {
            height: 2rem;

            padding-left: 0.9rem;
        }

        &_facebook {
            height: 2rem;

            padding-left: 0.9rem;
        }
    }

    &__oauthLabel {
        position: relative;
        right: 0;
        &_google{
            width: calc(100% - 2.4cm);
        }
        &_apple{
            width: calc(100% - 1.7cm);
        }
        &_facebook{
            width: 100%;
            margin-left: 7px;
        }
    }
}