@import "../styles/theme.scss";

.ContactForm {
    &__container {
        min-width: 500px;

        display: grid;
        grid-template-columns: 1fr;
    }

    &__commentContainer {
        width: 100%;
    }

    &__commentLabel {
        display: block;
    }

    &__comment {
        width: $input-width;
        height: 8em;
        margin-top: 0.5em;
        resize: vertical;

        border: none;
        border-radius: $input-radius;

        font-family: $font-family;

        padding: $input-padding;
        color: $input-color;
        background-color: $input-background;
    
        outline-style: $input-outline-style;
        outline-color: $input-outline-color;
        outline-width: $input-outline-width;
    
        &:focus {
            outline-color: $input-outline-color-focus;
        }
    
        &:disabled {
            color: black;
        }
    }

    &__phoneContainer {
        width: 100%;
        margin-top: 1em;
    }

    &__emailContainer {
        width: 100%;
        margin-top: 1em;
    }

    &__buttonContainer {
        width: 100%;
        margin-top: 1em;
    }
}