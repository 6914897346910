@import "../styles/theme.scss";

.Page {
  &__page {
    width: 100vw;
    height: 100%;
    position: relative;

    background: $secondary-background-color;

    // background-image: url("../res/images/solarpanels.jpeg");
    // background-repeat: no-repeat;
    // background-size: cover;
  }

  &__container {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    backdrop-filter: blur(2px);
  }
  &__bagcontainer {
    display: flex;
    gap: 0.2rem;
    background: $primary-color;
    width: 5.5rem;
    border-radius: 2rem;
    padding: 0.3rem;
    align-items: flex-end;
    color: black;
    position: absolute;
    top: 1rem;
    right: 30%;
    z-index: 1;
    padding: 0.5rem 1rem;
  }
  &__bagcontainertext {
    font-size: x-small;
  }
  &__bagcontainertext2 {
    font-weight: 500;
    font-size: medium;
    margin-left: 0.2rem;
  }
  &__box {
    position: relative;
    padding: 1.5cm;
    min-width: 25em;
    max-width: 80%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background: $secondary-background-color;
    // backdrop-filter: blur(8px);

    border-radius: 1cm;
  }

  &__logo {
    margin-bottom: 1em;
  }

  &__title {
    font-size: x-large;
    color: #e9a837;

    margin-bottom: 1rem;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // width: 80%;
    display: flex;
    padding: 2rem;
  }
}
