@import "../styles/theme.scss";

$border-color: $primary-color;
$icon-width: 1.5em;

.AllocationsForm {
  position: relative;

  &__summary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    margin-bottom: 2em;
  }

  &__heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.8em;
  }

  &__heading &__text {
    font-weight: 500;
    font-size: 1.3rem;
  }

  &__heading &__text span {
    color: red;
  }

  &__heading &__panels {
    font-size: 2.4rem;
    font-weight: 600;
    color: $border-color;
  }

  &__headingtwo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.8em;
  }

  &__headingtwo &__texttwo {
    font-weight: 500;
    font-size: 1.3rem;
    color: $border-color;
  }

  &__headingtwo &__panelstwo {
    font-size: 2rem;
    font-weight: 600;
    color: $border-color;
  }

  &__totalLabel {
    font-size: 1.3rem;
    font-weight: 700;
    color: $border-color;
    padding-right: 0.4em;
  }
  &__totalLabel sup {
    font-size: 0.6rem;
    vertical-align: super;
    font-weight: 500;
  }

  &__maxWatts {
    display: flex;
    align-items: center;
    gap: 0.4rem;
  }
  &__maxWatts &__unallocatedtext {
    color: red;
    font-weight: 400;
  }
  &__maxWatts &__paneltext {
    color: red;
    font-weight: 600;
    font-size: 1.3rem;
  }
  &__maxWatts &__paneltext sup {
    font-size: 0.6rem;
    vertical-align: super;
    font-weight: 500;
  }

  &__currentPercentage {
    display: flex;
    align-items: center;
    gap: 0.4rem;
  }
  &__currentPercentage &__paneltext {
    color: #34a853;
    font-weight: 600;
    font-size: 1.3rem;
  }
  &__currentPercentage &__allocatedtext {
    color: #34a853;
    font-weight: 400;
  }
  &__currentPercentage &__paneltext sup {
    font-size: 0.6rem;
    vertical-align: super;
    font-weight: 500;
  }

  &__allocations {
    display: flex;
    flex-direction: column;

    gap: 0.75em;
  }

  &__item {
    border-color: $border-color;
    border-style: solid;
    border-radius: $input-radius;
    border-width: 0.5px;
  }

  &__section1 {
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
    width: 100%;
    color: $popup-text-color;
    background-color: $border-color;
    border-top-right-radius: $input-radius;
    border-top-left-radius: $input-radius;
  }

  &__name {
    padding: 0.5em 1em 0.5em 1em;
    color: #444b54;
    font-size: larger;
    font-weight: 600;
  }

  &__payment {
    padding: 0.5em 1em 0.5em 1em;
    color: #3f3838;
    font-size: larger;
    font-weight: 600;
  }
  &__panelsnumber {
    padding: 0.5em 1em 0.5em 1em;
    color: #3f3838;
    font-size: larger;
    font-weight: 600;
  }

  &__edit {
  }

  &__iconContainer {
    width: $icon-width;
    display: flex;
    gap: 1rem;
    text-align: center;
  }

  &__button {
    &:hover {
      fill: $secondary-color;
    }
  }

  &__iconButton {
    width: $icon-width;

    border: none;
    background-color: transparent;

    fill: $secondary-color;
  }

  &__icon {
    width: $icon-width;
    height: $icon-width;

    &_delete {
      fill: $delete-color;
    }
  }

  &__section2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-top: 0.4rem;
    align-items: flex-end;
    padding-bottom: 0.5rem;
    width: 100%;
  }
  &__capacity {
    display: flex;
    width: 7.2rem;
    justify-content: flex-start;
    font-size: larger;
    font-weight: 600;
  }
  &__percentage {
    display: flex;
    width: 5.9rem;
    justify-content: flex-start;
    color: #5e5b57;
    font-size: larger;
    font-weight: 600;
  }
  &__wattsLabel {
    padding-left: 0.5em;
  }

  &__allocationActions {
    display: flex;
    align-content: center;
    gap: 0.5em;
    justify-content: space-between;
    width: calc(100% - 2em);

    padding: 0em 1em 0.5em 1em;
  }

  &__actions {
    margin-top: 1em;

    display: flex;

    gap: 1em;
  }
}
