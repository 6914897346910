@import "../styles/theme.scss";

.LoginForm {
    &__container {
        display: grid;
        row-gap: 0.5rem;
    }

    &__links {
        text-align: right;
    }

    &__link {
        width: fit-content;
        color: $text-color;
        background-color: initial;
    }
}