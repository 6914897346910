@import "../styles/theme.scss";

.AllocationForm {
  display: grid;
  grid-template-rows: auto;
  row-gap: 1em;

  &__submitLine {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1em;
  }

  &__links {
    display: grid;
    grid-template-rows: 1fr;
    row-gap: 0.5em;
  }

  &__link {
    justify-self: right;
    align-self: center;

    cursor: pointer;
  }
  &__message {
    color: $warning-color;
  }
  &__error {
    color: $error-color;
    font-size: small;
    margin-top: -0.5rem;
  }
}
