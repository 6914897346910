@import "../styles/theme.scss";

.TestimonialForm {
  &__testimonials {
    gap: 0.688rem;
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  &__testimonialContainer {
    width: 100%;
    height: 9.375rem;
    border-radius: $input-radius;
    display: flex;
    flex-direction: column;
    align-items: self-start;
    background-color: white;
    color: $popup-text-color;
  }
  &__container {
    display: flex;
    flex-direction: column;
    align-items: self-start;
    gap: 0.5rem;
  }

  &__testimonial {
    padding: 0.5rem;
    font-size: small;
    width: 10.4rem;
  }

  &__text {
    font-size: larger;
    font-weight: 600;
  }

  &__name {
    margin-top: 0.313rem;
    font-size: small;
    font-weight: 600;
  }

  &__location {
    font-weight: 600;

    font-size: x-small;
  }
  .stars {
    display: flex;
    align-items: flex-start;
    width: 100%;
    gap: 0.9rem;
    .star-element {
      color: "#E9A837";
      font-size: 16px;
    }
  }
}
