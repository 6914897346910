@import "../styles/theme.scss";

.PaymentSummaryForm {
  &__container {
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 1rem 0;
    border-top: 0.5px solid $primary-color;
    border-bottom: 0.5px solid $primary-color;
  }

  &__payment {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    img {
      height: 25px;
    }
  }

  &__paymentLabel {
    white-space: nowrap;
    text-align: left;
    font-size: small;
    color: $primary-color;

    span {
      font-size: large;
    }
  }

  &__paymentValue {
    white-space: nowrap;
    text-align: right;
    font-size: larger;
  }
}
