.TrackerEvent {
    display: contents;

    &__iconContainer, &__dateContainer {
        width: 2.4cm;
        height: 2.4cm;
        margin: 0 calc(0.8cm - 2px) 0 calc(0.8cm - 2px);

        border: 2px solid #70E9D4;
        border-radius: 1em;

        display: flex;
        flex-direction: column;

        justify-content: center;
        align-items: center;
    }

    &__iconContainer > svg {
        stroke: #70E9D4;
        height: 3rem;
        width: 3rem;
    }

    &__month {
        font-size: 0.8em;
        margin-bottom: 0.5em;
    }

    &__day {
        font-size: 1.5em;
    }

    &__bodyContainer {
        display: flex;
        flex-direction: column;

        margin: 0 1cm 0.5cm 1cm;
    }

    &__title {
        font-weight: 600;
        font-size: 1.2em;
        margin-bottom: 0.1cm;
    }

    &__label {
        font-size: 0.8em;
        color: black;

        width: max-content;
        padding: 0.1cm 0.5cm;
        // margin-bottom: 0.3cm;

        border-radius: 0.25em;

        &_normal {
            background-color: #70E9D4;
        }

        &_warning {
            background-color: #e9bf70;
        }

        &_important {
            background-color: #EB5757;
        }
    }

    &__amount {
        color: #E9A837;
        font-size: 1.5em;
    }

    &__detailsContainer {
        display: flex;
        flex-direction: column;

        color: #BDBDBD;
    }

    &__detail {
        margin: 0.04cm 0;
    }

    &__separatorContainer {
        display: flex;
        
        height: 100%;
        width: 100%;

        justify-content: center;

        &_none {
            height: 0;
        }
    }

    &__separator {
        min-height: 3rem;
        height: 100%;
        width: 2px;

        background-color: transparent;

        &_normal {
            background-color: #70E9D4;
        }

        &_more {
            background: linear-gradient(#70E9D4, #000000);
        }
    }
}