.OrganisationForm {
    &__who {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 1rem;
        height: 7rem;
        .Button{
            height:5rem;
            border-radius: 5px;
        }
    }

    &__existing {
        margin-top: 1rem;

        & > p {
            margin-bottom: 0.75rem;
        }
    }

    &__details {
        margin-top: 1rem;

        & > p {
            margin-bottom: 0.75rem;
        }

        & > div {
            padding-bottom: 0.5rem;

            &:last-of-type {
                padding-bottom: 0rem;
                margin-bottom:1.875rem;
            }
        }
    }

    &__addressManual {
        margin-top: 0.5rem;
    }
}