$background-color: #E9A837;
$color: black;
$background-color-checked: #70E9D4;
$color-checked: black;
$box-background-color: white;
$box-color: black;

$container-height: 2.4em;

$box-size: 2.4em;
$box-diameter: 2em;

.Checkbox {
    &__container {
        display: grid;
        grid-template-columns: max-content 1fr;
        align-items: center;
        column-gap: 0.5em;

        height: $container-height;

        border: none;
        border-radius: $container-height;

        background-color: $background-color;
        color: $color;

        &_checked {
            background-color: $background-color-checked;
        }
    }

    &__boxContainer {
        display: grid;
        align-items: center;
        justify-items: center;

        width: $box-size;
        height: $box-size;
    }

    &__box {
        display: grid;
        align-items: center;
        justify-items: center;

        width: $box-diameter;
        height: $box-diameter;

        border: none;
        border-radius: $box-diameter;

        background-color: $box-background-color;
        color: $box-color;
        
        align-items: center;
        justify-content: center;

        text-align: center;

        &_checked::before {
            content: "✓";
            font-size: xx-large;
            font-weight: bolder;
        }
    }

    &__text {
        margin-right: 1em;
    }
}