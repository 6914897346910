@import "../styles/theme.scss";

.VerificationForm {
    &__container {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 0.5em;
    }

    &__text {
        font-size: 10pt;
        
        & > p {
            &:last-child {
                margin-top: 1em;
                margin-bottom: 0.5em;
            }
            
            &:first-child {
                margin-top: 0;
            }
        }
    }

    &__input {
        width: 100%;
    
        margin-bottom: 0;

        &_email {
            margin-bottom: 1em;
        }

        &_verificationCode {
            margin-bottom: 1em;
        }
    }

    &__submitLine {
        margin-top: 1em;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 1em;
        width: 100%;
    }

    &__links {
        margin-top: 0.25rem;
        width: 100%;
        height:17px;

        .Button {
            &__text{
                font-size: 10pt;
                margin-top:-20px;
                font-family: 'poppins';
            }
            &:hover {
                color: $secondary-color-active;
            }
        }
        .ButtonLink {
            width: 100%;
            display: flex;
            justify-content: flex-end;
        }
    }
    
}
