@import "../styles/theme.scss";

.Dropdown {
    &__container {
        display: inline-block;
        position: relative;
        width: 100%;
    }

    &__inputAndButton {
        display: grid;
        grid-template-columns: 1fr auto;
        column-gap: 0.1rem;

        &:focus-within {
            border-radius: $input-radius;

            outline-style: solid;
            outline-width: 1px;
            outline-color: $input-outline-color-focus;
        }
    }

    &__input {
        border: none;
        border-radius: $input-radius 0 0 $input-radius;

        outline: transparent;

        &_noButton {
            border-radius: $input-radius;
        }

        &:focus {
            border-style: hidden;
        }
    }

    &__button {
        width: 3rem;

        outline: none;

        border-radius: 0 $input-radius $input-radius 0;
    }

    &__icon {
        width: 1rem;
    }

    &__icon path {
        fill: $text-color;
    }

    &__listContainer {
        display: none;
        position: absolute;
        
        z-index: 2;
        left: 1rem;
        width: calc(100% - 1rem);

        background-color: $input-background;

        border-style: solid;
        border-color: $secondary-color-active;
        border-width: 1pt;
        border-radius: 0 0 $input-radius $input-radius;

        &_open {
            display: block;
        }

        &_noButton {
            border-radius: 0 $input-radius $input-radius $input-radius;
        }
    }

    &__list {
        padding: 1rem;
    }

    &__listItem {
        display: block;
        width: 100%;

        text-align: left;
        text-decoration: none;
        font-size: 10pt;
        white-space: no-wrap;

        border-style: none;

        color: $input-color;
        background-color: $input-background;

        margin-top: 0.3rem;
        margin-bottom: 0.3rem;

        &:last-of-type {
            margin-bottom: 0;
        }
    
        &:hover {
            color: $tertiary-color;
        }

        &_highlight {
            color: $tertiary-color;
            background-color: $input-color;
        }
    }
}