@import "../styles/theme.scss";

.Input {
    width: calc(100% - 2em);

    border: none;
    border-radius: $input-radius;
    padding: 0.6em 1em;

    margin-bottom: 0;

    color: $input-color;
    background-color: $input-background;
    
    outline-style: solid;
    outline-color: transparent;
    outline-width: 1px;

    &_error {
        outline-color: $input-outline-color-error;
    }

    &:focus {
        outline-color: $secondary-color-active;
    }

    &:disabled {
        color: black;
    }

    &__container {
        display: grid;
        grid-template-columns: auto 1fr auto;
        column-gap: 0.1rem;
    }

    &__number {
        border-radius: 0;

        /* Chrome, Safari, Edge, Opera */
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        &[type=number] {
            -moz-appearance: textfield;
        }
    }

    &__decrement {
        border: none;
        border-top-left-radius: $input-radius;
        border-bottom-left-radius: $input-radius;
        padding: 0.6em 1em;
    
        margin-bottom: 0;
    
        color: $input-color;
        background-color: $input-background;
        
        outline-style: solid;
        outline-color: transparent;
        outline-width: 1px;
    
    }

    &__increment {
        border: none;
        border-top-right-radius: $input-radius;
        border-bottom-right-radius: $input-radius;
        padding: 0.6em 1em;
    
        margin-bottom: 0;
    
        color: $input-color;
        background-color: $input-background;
        
        outline-style: solid;
        outline-color: transparent;
        outline-width: 1px;
            
    }
}
