@import "../styles/theme.scss";

.Button {
    position: relative;
    cursor: pointer;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 2.4rem;
    border: none;
    border-radius: $button-radius;
    padding: 0.6em 1em;
    margin-bottom: 0;
    background-color: $input-background;
    color: #000000;
    transition: color .6s cubic-bezier(.19,1,.22,1);
    &:hover {
        color: $secondary-color-active;
    }

    &_bordered {
        border-color: $secondary-color-active;
        border-style: solid;

        color: gray;

        &:hover {
            color: $secondary-color-active;
        }
    }

    &_selected {
        border-color: $secondary-color-active;
        border-style: solid;

        color: $secondary-color-active;
    }

    &_deselected {
        color: gray;
    }

    &_disabled {
        cursor: not-allowed;
        border-color: $button-disabled-background-color;

        &:hover {
            color: $button-disabled-color;
        }
    }

    &__slider {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;

        width: 100%;
        height: 200%;
        background: linear-gradient(180deg, $secondary-color-active 0 50%, transparent 50% 100%);

        transition: top .6s cubic-bezier(.19,1,.22,1);

        &:hover {
            top: -100%;
        }

        &_selectable {
            top: -100%;

            &:hover {
                top: -100%;
            }
        }

        &_selected {
        }
    
        &_deselected {
        }

        &_bordered {
            top: -100%;
        }

        &_disabled {
            background: gray;
        }
    }

    &__text {
        position: relative;
        font-size: 12pt;
        z-index: 1;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: none;
        white-space: nowrap;
    }
}

.ButtonLink {
    width: fit-content;

    border: none;
    border-radius: 0;
    padding: 0;

    color: $text-color;
    background-color: transparent;
}