@import "../styles/theme.scss";

.Footer {
  padding-top: 2rem;
  color: $sidebar-text-color;
  font-size: 0.8rem;
  width: 41.25rem;

  &__footernoteWrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    border-top: 0.01rem solid;
    padding-top: 3rem;
  }

  &__img {
    margin-top: 3.9rem;
  }

  &__imgFooter {
    margin-top: 0.813rem;
  }

  &__copyright {
    padding-bottom: 2.5em;

    text-align: justify;
    border-bottom: 0.01rem solid;
  }

  &__footnote {
    padding-right: 1em;
    margin-right: 1.3em;

    text-align: justify;
  }

  &__footnote > p {
    margin-bottom: 0.5rem;
  }

  &__flexWrapper {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    padding: 2rem 0px 0px;
  }

  &__solarCloud {
    height: 2.5rem;
  }

  &__iconWrapper {
    display: flex;
    justify-content: space-evenly;
    height: 10px;
    margin-top: 1rem;
  }
}
