@import "../styles/theme.scss";

$border-color: $primary-color;
$icon-width: 1.5em;

.PaymentTypes {
    margin-top: 1.5rem;   
    font-size: 13pt;
    &__logos {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 4rem;
    
        } 
        &__text {
        margin-top: 2.2rem; 
        font-size: 1.37rem; 
        align-items: center;    
        display: flex;
        justify-content: center;  }


    &__paymentTypes {
        margin-top: 2rem;
        
    }

    &__logo {
        margin-top: 1rem;

        &_paypal {
            background-color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;
            border: 1px solid white;
            border-radius: 5px;
        }

        &_paypal img {
            height: 1.475rem;
            padding: 0.4rem;
        }

        &_google { 
            background-color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;
            border: 1px solid white;
            border-radius: 5px;
        }

        &_google img {
            height: 1.475rem;
            padding: 0.4rem;        }

        &_apple {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &_apple img {
            height: 4.9rem;
        }

        &_mint {
            background-color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid white;
            border-radius: 5px;
        }

        &_mint img {
            height: 1.5rem;
            padding: 0.513rem;
        }
    }
}