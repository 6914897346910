@use "sass:color";
@import "./styles/theme.scss";
@import "./styles/_scroll.scss";

* {
    margin: 0;
    padding: 0;
}

.App {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 11.5pt;
    color: $text-color;
    z-index: 0;

    position: relative;
    width: 100vw;
    height: 100vh;
    
    flex-direction: column;
    align-items: center;

    &__container {
        position: relative;
    }
}

.UserInfo {
    position: fixed;
    bottom: 0;
    right: 0;
}

a {
    color: inherit;
    text-decoration: none;

    &:visited {
        color: inherit;
    }

    &:hover {
        color: color.scale($secondary-color, $lightness: -40%);
    }

    &:focus {
        color: color.scale($secondary-color, $lightness: -25%);
    }

    &:active {
        color: color.scale($secondary-color, $lightness: -30%);
    }
}
