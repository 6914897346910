$widget-width: 100%;
$background-colour: rgba(20,20,22,0);
$forground-colour: rgba(255,255,255,1);
$logo-colour: rgba(254,171,67,1);
$slider-bar-colour: rgba(225,228,233,1);

.CalculatorPanel {
    width: $widget-width;

    &__container {
        position: relative;
        width: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__body {
        width: 100%;
        color: $forground-colour;
        background-color: $background-colour;
    }

    &__unitContainer {
        display: block;
        text-align: center;

        font-family: "Poppins", sans-serif;
        font-weight: 600;
        font-size: 26px;
        color: $forground-colour;
        text-decoration: none;

        margin: 16px 0px;
    }

    &__unitSwitch {
        position: relative;
        display: inline-block;
        top: 8px;
        width: 50px;
        height: 20px;
        background-color: $logo-colour;
        border-radius: 20px;

        &_input {
            opacity: 0;
            width: 0;
            height: 0;
        }

        input:checked + &_visual:before {
            transform: translateX(28px);
        }

        &_visual {
            background-color: $logo-colour;
            border-radius: 20px;
        }

        &_visual {
            background-color: $logo-colour;
            border-radius: 20px;
        }

        &_visual:before {
            position: absolute;
            content: "";
            height: 16px;
            width: 16px;
            border-radius: 50%;
            left: 3px;
            bottom: 2px;
            background-color: $forground-colour;
            -webkit-transition: .4s;
            transition: .4s;
        }
    }

    &__rangeContainer {
        position: relative;
        left: 0mm;
        right: 0;
        width: calc(100% - 30mm);
        margin: 5mm 15mm 5mm 15mm;
    }

    &__rangeValue {
        position: absolute;
        padding: 0;
        margin: 0;

        font-family: "Poppins", sans-serif;
        font-weight: 600;
        font-size: 26px;
        color: $logo-colour;
        text-decoration: none;
    }

    &__rangeUnits {
        position: absolute;
        padding: 0;
        margin: 0;

        font-family: "Poppins", sans-serif;
        font-weight: 600;
        font-size: 10px;
        color: $logo-colour;
        text-decoration: none;
    }

    &__range {
        width: 100%;
        padding: 0;
        margin: 50px 0 20px 0;
    }

    &__range {
        -webkit-appearance: none;
        height: 5px;
        border-radius: 5px;
        background: $slider-bar-colour;
        outline: none;
        opacity: 0.7;
        -webkit-transition: 0.2s;
        transition: opacity 0.2s;
    }

    &__range::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        background: $logo-colour;
        cursor: pointer;
    }

    &__range::-moz-range-thumb {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        background: $logo-colour;
        cursor: pointer;
    }

    &__rangeEntryContainer {
        text-align: right;
    }

    &__rangeEntry {
        display: inline-block;
        visibility: hidden;
        margin-top: 20pt !important;
        margin-left: auto;

        background-color: #000000;
        color: #FFFFFF;
        border: 2px solid #E9A837;
        border-radius: 15pt;

        height: 30pt;
        width: 80pt;

        font-family: "Poppins", sans-serif;
        font-weight: 600;
        font-size: 26px;
        color: $logo-colour;
        text-align: center;

        &::-webkit-inner-spin-button {
            // -webkit-appearance: none !important;
            opacity: 1 !important;
            background: transparent !important;
            cursor: pointer;
        }
    }

    &__resultContainer {
        text-align: center;
        padding: 20pt 0 20pt 0;
    }

    &__result {
        &_savings {
            display: inline-block;
            text-align: center;
            margin: 0 25px;
        }

        &_capacity {
            display: inline-block;
            text-align: center;
            margin: 0 25px;
        }
    }

    &__resultLabel {
        &_savings {
            font-family: "Poppins", sans-serif;
            font-weight: 600;
            font-size: 26px;
            color: $forground-colour;
            text-decoration: none;
        }

        &_capacity {
            font-family: "Poppins", sans-serif;
            font-weight: 600;
            font-size: 26px;
            color: $forground-colour;
            text-decoration: none;
        }
    }

    &__resultValue {
        font-family: "Poppins", sans-serif;
        display: block;
        color: $logo-colour;

        &_savings {
            font-family: "Poppins", sans-serif;
            font-weight: 600;
            font-size: 22px;
        }

        &_savings::before {
            font-family: "Poppins", sans-serif;
            font-weight: 600;
            font-size: 22px;
            content: "$";
        }

        &_capacity {
            font-family: "Poppins", sans-serif;
            font-weight: 600;
            font-size: 22px;
        }

        &_capacity::after {
            font-family: "Poppins", sans-serif;
            font-weight: 600;
            font-size: 22px;
            content: "kW";
        }
    }

    &__benefits {
        max-height: 500px;
        overflow: hidden;

        &_container {
        }

        &_ul {
            list-style-type: none;
        }

        &_li {
            font-family: "Poppins", sans-serif;
            color: $forground-colour;
            font-size: 16px;
            line-height: 30px;
            width: 46%;
            background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAjCAYAAAAe2bNZAAABJWlDQ1BJQ0MgcHJvZmlsZQAAKJFjYGAycHRxcmUSYGDIzSspCnJ3UoiIjFJgP8/AxsDMAAaJycUFjgEBPiB2Xn5eKgMG+HaNgRFEX9YFmYUpjxdwJRcUlQDpP0BslJJanMzAwGgAZGeWlxQAxRnnANkiSdlg9gYQuygkyBnIPgJk86VD2FdA7CQI+wmIXQT0BJD9BaQ+Hcxm4gCbA2HLgNglqRUgexmc8wsqizLTM0oUDC0tLRUcU/KTUhWCK4tLUnOLFTzzkvOLCvKLEktSU4BqIe4DA37n/Jz8It/EkuQMBWCwkehrIgAoHiCsb3eg4WuFEEOA5NKiMiiTkfEsYT7CjKZsBgY3oH/YzBBiQcoMDCsqGRikryPElDwYGARVGRg2KgAASFhSQ0iq7YkAAAAGYktHRAD/AP8A/6C9p5MAAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfkBRwBLRJBf9XzAAACQ0lEQVRYw82YPWgUQRiGn90YJREyamFnoyPogYWgpAko4g8iWAiCJ4jEMCBIjFpECNioEIKIIkLAgQgW/pRWIlgrIomNISADFhZWigNCNFHPZk6GYczt7d3u+lU737t7++y78803t4nRYgYYpvqYTowWDf6TWBWMl4FZ4DuQFHjf30AfsBvoicF8BY5LZV+U5YTR4hDwBBAAqactlAkCIJV9Dsw3xz7Mr4qmyo8YTFrwK+n7h5SWAuCBXAWOtjovLQFEAVeApUphjBb7gLtu+LEyGKPFZuARsNqlkiqdmQY2euMNlcAYLS4BB4P0ltJhjBY14FpE2lOFM7eB/kj+U2EwRoskkqsDByKnzwAT7XbtLBB1YFAqeyHID7j1JIwHUtmRLL/tO/MzA8hl4CEwZrTYFsgngO1B7qlU9nSe/UyjxZpxAzjmpUeBc05fC4wHl70HRtpxPc3gxlngbQACcNJosckdnwpK9xtQl8p+7ioMsB4YiOTXeU8+GmjXpbJz7c7HljBS2UlgB/AqIh8xWlwEtnq5l1LZqTwV6sMkKwC9A/YC9wNpJ3AT6PWK4Hze5SITjANaksqecRO5GT3BdbeksrOFw3hQ48CdiPQBmKKDyLXtlMqOuV29HxPtVk83e9Mw8MYdP5PKPu60qeWGkcouOqAFYLIbHbajri2VnQeGgNfd/nvbyAn0pUOGRu5qKiDWxGBqRov9ZVIYLQ4Dtb9uBJ9EloE5YLFAp5r36wd2+YaEm6teYLCq7zNppN9UFff+AEZYkSgtCKNdAAAAAElFTkSuQmCC) no-repeat left 8px;
            background-size: 15px auto;
            padding-left: 24px;
            text-align: left;
        }

        & li:nth-child(odd) {
            float: left;
        }

        & li:nth-child(even) {
            float: right;
        }
    }

    &__results {
        display: flex;

        gap: 2em;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    &__savings {
        display: flex;

        flex-direction: column;
        justify-content: center;
        align-items: center;

        font-family: "Poppins", sans-serif;
        font-weight: 600;
        font-size: 26px;
    }

    &__savingsLabel {
    }

    &__savingsValue {
        color: $logo-colour;
    }

    &__capacity {
        display: flex;

        flex-direction: column;
        justify-content: center;
        align-items: center;

        font-family: "Poppins", sans-serif;
        font-weight: 600;
        font-size: 26px;
    }

    &__capacityLabel {
    }

    &__capacityValue {
        color: $logo-colour;
    }

    &__buyContainer {
        display: flex;

        gap: 2em;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;

        padding-top: 1em;

        font-family: "Poppins", sans-serif;
        font-weight: 600;
        font-size: 22px;
        color: $logo-colour;
    }
}
