@use "../buttons.scss";

.BuyPanelsPage {
    &__panelsLinkContainer {
        margin-top: 20px;
        text-align: center;
    }

    &__panelsLink {
        cursor: pointer;
    }

    &__actionContainer {
        width: 100%;
        margin-top: 10px;

        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 1em;
    }

    &__buttonContainer {
        width: 100%;
        margin-top: 20px;

        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 1em;
    }

    &__paypalContainer {
        width: 100%;
        height: 36pt;
    }

    &__payment {
        @include buttons.solarcloud-button;

        text-align: center;

        &__container {
            width: 100%;
        }
    }

    &__paymentActive {
        @include buttons.solarcloud-button;

        text-align: center;

        &__container {
            width: 95%;
        }
    }

    &__cancel {
        @include buttons.solarcloud-button;

        text-align: center;
    }

    &__checkoutButton {
        @include buttons.trolley-button;
    }

    &__paymentsContainer {
        display: grid;
        grid-template-columns: auto auto;
    }

    &__contactContainer {
        display: grid;
        grid-template-columns: 1fr 1fr 2fr;
        column-gap: 1em;
        align-items: center;
        margin-top: 1em;
    }

    &__contactButton {
        @include buttons.solarcloud-button;
    }

    &__contactCancel {
        cursor: pointer;
    }
}
    .footerWrapper{
        max-width: 85%;
    }
