@use "../buttons.scss";
@import "../styles/theme.scss";

.BuyPanelsPanel {
    display: inline-block;
    position: relative;

    width: 100%;

    margin-top: 2rem;

    &:last-of-type {
        margin-bottom: 2rem;
    }

    &__actionContainer {
        width: 100%;
        display: flex;
        column-gap: 1em;
        .backButton{
            width:18rem;
            &__text{
                color: gray;
    
               }
            .Button__slider{
           background: #24262D;
           
           &:hover{
            background: #24262D;
            color: gray;

           }
        }

        }
    }

    &__buttonContainer {
        width: 100%;
        margin-top: 20px;

        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 1em;
       
    }

    &__message {
        font-size: x-small;
    }
}
