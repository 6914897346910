@import "../styles/theme.scss";

.Sidebar {
    color: $sidebar-text-color;
    background-color: $secondary-background-color;
    font-size: 1.2em;

    &__container {
        padding: 0.6em 0;
        display: grid;
        grid-template-columns: minmax(1cm, 1fr) max-content minmax(1cm, 1fr);
    }

    &_open {
        display: inline-block;
        position: relative;
        height: 100vh;
        width: 300px;
    }

    &_closed {
    }
}

.SidebarButton {
    color: $sidebar-text-color;
    background-color: $secondary-background-color;
    z-index: 1;

    &__container {
        display: grid;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0.25em;
        left: 0.25em;
        width: 60px;
        height: 60px;
        background-color: $secondary-background-color;
        border-radius: 50%;
    }

    &__iconButton {
        width: 29.21px;
        height: 50.61px;

        background-color: $secondary-background-color;
        border: 0;
    }

    &__icon {
        width: 29.21px;
        height: 50.61px;
    }
}

.SidebarHeader {
    padding: 1em 1em;

    &__container {
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    &__iconButton {
        width: 29.21px;
        height: 50.61px;
        margin-right: 0;

        background-color: inherit;
        border: 0;
        margin-right: 0.7cm;
    }

    &__icon {
        width: 29.21px;
        height: 50.61px;
    }

    &__pageName {
        font-size: 1.6em;
    }
}

.SidebarSpacer {
    display: contents;
        
    &__container {
        padding: 1em 0;
    }

    &__line {
        height: 0.5px;
        background-color: $sidebar-text-color;
    }
}