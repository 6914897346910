@use "sass:color";
@import "../styles/theme.scss";


.MonthSpinner {
    display: grid;
    grid-template-columns: 10mm max-content 10mm;
    column-gap: 1em;

    justify-items: center;
    align-items: center;
    text-align: center;

    &__arrow {
        display: flex;
    
        width: 2 * $secondary-background-radius;
        height: 2 * $secondary-background-radius;
    
        background-color: $background-color;
    
        font-size: x-large;
    
        border: none;
        border-radius: 2 * $secondary-background-radius;
    
        justify-content: center;
        align-items: center;
    
        cursor: pointer;
    
        &:hover {
            background-color: $primary-color-hover;
        }
    
        &:focus {
            background-color: $primary-color-focus;
        }
    
        &:active {
            background-color: $primary-color-active;
        }

        &_disabled {
            background-color: color.scale($background-color, $lightness: 15%);

            cursor: default;

            &:hover {
                background-color: color.scale($background-color, $lightness: 15%);
            }

            &:focus {
                background-color: color.scale($background-color, $lightness: 15%);
            }

            &:active {
                background-color: color.scale($background-color, $lightness: 15%);
            }
        }
    }

    &__text {
        width: 25mm;
        color: $text-color;
    }
}