@import "../styles/theme.scss";

.SidebarItem {
    display: contents;
    
    &__container {
        padding: 0.6em 0;
    }

    &__icon {
        display: inline;

        height: 2em;
        width: 2em;

        vertical-align: middle;
    }

    &__icon > svg {
        stroke: $sidebar-text-color;
        fill: none;
    }

    &__icon_selected > svg {
        stroke: $sidebar-text-color-selected;
    }

    &__pageName {
        display: inline;

        padding-left: 1em;

        vertical-align: middle;

        &_selected {
            color: $sidebar-text-color-selected;
        }
    }
}