$widget-width: 100%;
$background-colour: rgba(20,20,22,0);
$forground-colour: rgba(255,255,255,1);
$logo-colour: rgba(254,171,67,1);
$slider-bar-colour: rgba(225,228,233,1);

.Slider {
    &__sliderContainer {
        position: relative;
        left: 0mm;
        right: 0;
        width: calc(100% - 30mm);
        margin: 5mm 15mm 5mm 15mm;
    }

    &__sliderValue {
        position: absolute;
        padding: 0;
        margin: 0;

        font-family: "Poppins", sans-serif;
        font-weight: 600;
        font-size: 26px;
        color: $logo-colour;
        text-decoration: none;
        white-space: nowrap;
    }

    &__sliderUnits {
        position: absolute;
        padding: 0;
        margin: 0;

        font-family: "Poppins", sans-serif;
        font-weight: 600;
        font-size: 10px;
        color: $logo-colour;
        text-decoration: none;
        white-space: nowrap;
    }

    &__slider {
        width: 100%;
        padding: 0;
        margin: 50px 0 20px 0;
    }

    &__slider {
        -webkit-appearance: none;
        height: 5px;
        border-radius: 5px;
        background: $slider-bar-colour;
        outline: none;
        opacity: 0.7;
        -webkit-transition: 0.2s;
        transition: opacity 0.2s;
    }

    &__slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        background: $logo-colour;
        cursor: pointer;
    }

    &__slider::-moz-range-thumb {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        background: $logo-colour;
        cursor: pointer;
    }

    &__sliderEntryContainer {
        text-align: right;
    }

    &__sliderEntry {
        display: inline-block;
        // visibility: hidden;
        margin-left: auto;

        background-color: #000000;
        color: #FFFFFF;
        border: 2px solid #E9A837;
        border-radius: 15pt;

        height: 30pt;
        width: 80pt;

        font-family: "Poppins", sans-serif;
        font-weight: 600;
        font-size: 26px;
        color: $logo-colour;
        text-align: center;

        &::-webkit-inner-spin-button {
            // -webkit-appearance: none !important;
            opacity: 1 !important;
            background: transparent !important;
            cursor: pointer;
        }

        &_units {
            margin-top: 20pt !important;
        }

        &_noUnits {
            margin-top: 10pt !important;
        }
    }
}
