@import "../styles/theme.scss";

.OrdersPanel {
    // &__separator {
    //     grid-column: 1 / -1;
    //     background-color: $primary-color;

    //     height: 0.5px;
    //     width: 100%;

    //     align-self: initial;
    //     justify-self: initial;
    // }

    &__loading {
        grid-column: 1 / -1;
    }

    &__loading::before {
        content: "Loading";
    }

    &__container {
        display: grid;
        grid-template-columns: 1fr max-content max-content;

        row-gap: 0.5em;

        align-content: center;
        // justify-items: center;
    }

    &__item {
        display: contents;
    }

    &__date {
        grid-column: 1;

        display: flex;
        align-items: center;

        height: 2em;
        padding: 1em 3em 1em 1em;

        border-top-left-radius: $secondary-background-radius;
        border-bottom-left-radius: $secondary-background-radius;

        // font-size: 0.8em;
        color: $sidebar-text-color;
        background-color: $background-color;
    }

    // &__invoiceNumber {
    //     grid-column: 2;
    // }

    // &__tax {
    //     grid-column: 3;
    // }

    &__total {
        grid-column: 4;

        display: flex;
        align-items: center;
        justify-content: flex-end;

        height: 2em;
        padding: 1em 1.5em 1em 1em;

        color: $secondary-color;
        background-color: $background-color;
    }

    // &__status {
    //     grid-column: 5;
    // }

    &__actions {
        grid-column: 6;

        display: flex;
        align-items: center;

        height: 2em;
        padding: 1em 1em 1em 0em;

        border-top-right-radius: $secondary-background-radius;
        border-bottom-right-radius: $secondary-background-radius;

        background-color: $background-color;
    }
}