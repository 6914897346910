.Messages {
    position: absolute;
    top: 0;
    left: 20%;
    right: 20%;

    display: flex;
    flex-direction: column;

    &__message {
        z-index: 1;
    }
}