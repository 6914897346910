@import "../styles/theme.scss";

.BuyPanelsCompleteForm {


    &__textSection > p {
        margin-top: 0.2rem;
    }

    &__text {
        font-size: small;
        margin-bottom: 2rem;
       
    }
    &__subText {
        font-size: x-small;
    }
    &__wrapperEmail{
        margin-top: 1.5rem;
    }

    &__navButton {
        display: flex;
        width: 100%;
        justify-content: center;

        .BuyPanelsPanel__actionContainer {
            width: 50%;
            margin-top: 3rem;
        }
    }
    &__availableText{
        font-size: x-small;
        width:50%;
        margin-top: 1.5rem !important;
    }
}