@import "../styles/theme.scss";

.SignupForm {
    &__text {
        
        & > p {
            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-top: 1em;
                margin-bottom: 0.5em;
            }
        }
    }

    &__spacer {
        height: 1em;

        &_large {
            height: 2em;
        }
    }

    &__inputContainer{
        display: grid;
        row-gap: 0.5rem;
    }

    &__links {
display: flex;   
justify-content: flex-end; 
    .Button{
            &__text{
                font-size: 10pt;
            }
        }
    }
}
