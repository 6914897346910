@use "./icons";
@import "./styles/theme.scss";

@mixin solarcloud-button {
    &__container {
        display: inline-grid;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    
        cursor: pointer;
    
        background-color: transparent;
        border: $button-border-width solid #E9A837;
        border-radius: calc(#{$button-font-size * 0.5} + #{$button-padding-top} + #{$button-border-width});
    
        color: #000000;
        transition: color .6s cubic-bezier(.19,1,.22,1);
    
        &:hover {
            color: #E9A837;
        }
            
        &_disabled {
            background-color: #A1A1A6;
        }
    }

    &__label {
        font-weight: 700;

        grid-row: 1;
        grid-column: 2;
    }

    &__secondaryLabel {
        font-size: smaller;
        grid-row: 2;
        grid-column: 2;
    }
}

@mixin solarcloud-icon-button($content) {
    @include solarcloud-button();

    &__icon {
        &::before {
            content: $content;
            height: 1em;
        }

        margin-right: 10pt;

        grid-row: 1 / 3;
        grid-column: 1;
    }
}

@mixin trolley-button {
    @include solarcloud-icon-button(icons.$trolley-icon);
}
