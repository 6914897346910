@import "../styles/theme.scss";

$image-width: 1.5rem;
$image-height: 1.5rem;
$text-padding-top: 0.25rem;

.Breadcrumbs {
    display: inline-block;
    position: relative;
    font-size: 12pt;

    margin-bottom: 3.75rem;

    text-align: center;
    vertical-align: middle;

    &__crumb {
        display: inline-block;

        vertical-align: middle;
    }

    &__separator {
        display: inline-block;
        width: $image-width;
        height: 2.1rem;
        vertical-align: middle;

        &>svg {
            display: block;
            height: 100%;
            width: 100%;
        }

        &_pastLeft .left {
            stroke: $primary-color;
            fill: $primary-color;
        }

        &_pastRight .right {
            stroke: $primary-color;
            fill: $primary-color;
        }

        &_currentLeft .left {
            stroke: $secondary-color;
            fill: $secondary-color;
        }

        &_currentRight .right {
            stroke: $secondary-color;
            fill: $secondary-color;
        }

        &_futureLeft .left {
            stroke: $background-color;
            fill: $background-color;
        }

        &_futureRight .right {
            stroke: $background-color;
            fill: $background-color;
        }
    }

    &__crumbText {
        display: inline-block;
        height: $image-height;
        width: auto;
        height: 1.875rem;
        padding-top: $text-padding-top;

        vertical-align: middle;

        &_leftMost {
            padding-left: 0.5rem;
            border-top-left-radius: 10%;
            border-bottom-left-radius: 10%;
        }

        &_rightMost {
            padding-right: 0.5rem;
            border-top-right-radius: 10%;
            border-bottom-right-radius: 10%;
        }

        &_past {
            background-color: $primary-color;
            color: $popup-text-color;
        }

        &_current {
            background-color: $secondary-color;
            color: $popup-text-color;
        }

        &_future {
            background-color: $background-color;
            color: $sidebar-text-color;
        }
    }
}