@use "../buttons.scss";

.ContactPage {
    &__listTitle {
        width: 100%;
        margin-top: 1em;
    }

    &__listItem {
        list-style: none;
    }

    &__listLabel {
        width: 100%;
        margin-left: 1em;
    }

    &__commentContainer {
        width: 100%;
        margin-top: 1em;
    }

    &__commentLabel {
        display: block;
    }

    &__comment {
        width: 100%;
        height: 5em;
        margin-top: 0.5em;

        border: none;
        border-radius: 0.5cm;
        padding: 8pt 12pt;
    }

    &__phoneContainer {
        width: 100%;
        margin-top: 1em;
    }

    &__emailContainer {
        width: 100%;
        margin-top: 1em;
    }

    &__buttonContainer {
        margin-top: 1em;
    }

    &__cancelLink {
        margin-left: 1em;
        
        cursor: pointer;
    }
}
